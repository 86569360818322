import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Spin, notification } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CaseLists from './CaseList';
import CaseDeleteCallbackModal from './CaseDeleteCallbackModal';
import { PageHeader } from '../../components/page-headers/page-headers';
import { BasicFormWrapper, Main } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { Modal } from '../../components/modals/antd-modals';
import {
  createCase,
  getClients,
  getComplexities,
  getDepartments,
  getMatterType,
  getRegions,
} from '../../redux/case/actionCreator';

const { Option } = Select;

function Cases() {
  const { t, i18n } = useTranslation();
  const {
    departments,
    regions,
    complexities,
    matterTypes,
    complexityLoading,
    matterTypeLoading,
    departmentsLoading,
    regionsLoading,
    clients,
    clientLoading,
    addCaseLoading,
  } = useSelector((state) => state.case);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [mtDefaultValue, setmtDefaultValue] = useState('');
  const [departmentDefaultValue, setDepartmentDefaultValue] = useState('');
  const [regionDefaultValue, setRegionDefaultValue] = useState('');
  const [complexityDefaultValue, setcomplexityDefaultValue] = useState('');
  const [clientDefaultValue, setclientDefaultValue] = useState('');
  const [casesLen, setcasesLen] = useState(0);
  useEffect(() => {
    if (!clientLoading && clients && clients.length > 0) {
      setclientDefaultValue(clients[0]._id);
    }
  }, [clientLoading, clients]);

  useEffect(() => {
    if (!matterTypeLoading && matterTypes && matterTypes.length > 0) {
      setmtDefaultValue(matterTypes[0]._id);
    }
  }, [matterTypeLoading, matterTypes]);

  useEffect(() => {
    if (!departmentsLoading && departments && departments.length > 0) {
      setDepartmentDefaultValue(departments[0]._id);
    }
  }, [departmentsLoading, departments]);

  useEffect(() => {
    if (!complexityLoading && complexities && complexities.length > 0) {
      setcomplexityDefaultValue(complexities[0]._id);
    }
  }, [complexityLoading, complexities]);

  useEffect(() => {
    if (!regionsLoading && regions && regions.length > 0) {
      setRegionDefaultValue(regions[0]._id);
    }
  }, [regionsLoading, regions]);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    visible: false,
    modalType: 'primary',
    checked: [],
    responsive: 0,
    collapsed: false,
  });
  useEffect(() => {
    dispatch(getMatterType());
    dispatch(getDepartments());
    dispatch(getRegions());
    dispatch(getComplexities());
    dispatch(getClients());
    return () => {
      setState({
        visible: false,
        modalType: 'primary',
        checked: [],
        responsive: 0,
        collapsed: false,
      });
    };
  }, [dispatch]);

  const showDeleteModal = () => {
    setdeleteModal(true);
  };
  const cancelDeleteModal = () => {
    setdeleteModal(false);
  };

  const showModal = () => {
    setState({
      ...state,
      visible: true,
    });
  };

  const onCancel = () => {
    setState({
      ...state,
      visible: false,
    });
  };

  const handleOk = (values) => {
    const onSuccess = () => {
      form.resetFields();
      onCancel();
    };
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    if (user) {
      const issueDate = new Date();
      const body = { ...values, issueDate, manager: user?._id };
      dispatch(createCase(body, t, onSuccess));
    } else {
      notification.error({
        message: t('uknownerror_createc'),
      });
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <PageHeader
        title={t('cases')}
        subTitle={
          <>
            {casesLen} {t('Running Cases')}
          </>
        }
        buttons={[
          <div key="1" className="page-header-actions">
            <Button onClick={showModal} size="small" type="primary">
              <FeatherIcon icon="plus" size={14} />
              {t('New Matter')}
            </Button>
          </div>,
        ]}
      />
      <Main>
        <CaseLists setcasesLen={setcasesLen} showDeleteModal={showDeleteModal} />
        <CaseDeleteCallbackModal visible={deleteModal} onClose={cancelDeleteModal} />
        <Modal type={state.modalType} title={null} visible={state.visible} footer={null} onCancel={handleCancel}>
          <div className="project-modal" style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter' }}>
            <BasicFormWrapper>
              <Form form={form} name="createProject" onFinish={handleOk}>
                <Form.Item
                  initialValue={clientDefaultValue}
                  name="owner"
                  label={t('Client')}
                  rules={[{ required: true, message: 'Please select a matter type!' }]}
                >
                  <Select loading={clientLoading} style={{ width: '100%' }}>
                    {clientLoading ? (
                      <Option value="loading" disabled>
                        <Spin size="small" /> {t('loading')}
                      </Option>
                    ) : (
                      clients &&
                      clients?.length > 0 &&
                      clients?.map((item, index) => (
                        <Option key={`cl_${index?.toString()}`} value={item?._id}>
                          {i18n.language === 'ar' ? item?.fullname_ar : item?.fullname_us}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  initialValue={mtDefaultValue}
                  name="matterType"
                  label={t('Matter Type')}
                  rules={[{ required: true, message: t('pls_select_matterType') }]}
                >
                  <Select loading={matterTypeLoading} style={{ width: '100%' }}>
                    {matterTypeLoading ? (
                      <Option value="loading" disabled>
                        <Spin size="small" /> {t('loading')}
                      </Option>
                    ) : (
                      matterTypes &&
                      matterTypes?.length > 0 &&
                      matterTypes?.map((item, index) => (
                        <Option key={`mt_${index?.toString()}`} value={item?._id}>
                          {i18n.language === 'ar' ? item?.name_ar : item?.name_us}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: t('pls_case_description_en') },
                    {
                      pattern: /^[A-Za-z\s\-_./\\()]+$/,
                      message: t('desc_en_condition'),
                    },
                  ]}
                  name="description_us"
                  label={t('case_en')}
                >
                  <Input.TextArea rows={2} placeholder={t('case_desc_en')} />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: t('pls_case_description_ar') },
                    {
                      pattern: /^[\u0600-\u06FF0-9\s\-_./\\()]+$/,
                      message: t('desc_ar_condition'),
                    },
                  ]}
                  name="description_ar"
                  label={t('case_ar')}
                >
                  <Input.TextArea rows={2} placeholder={t('case_desc_ar')} />
                </Form.Item>
                <Form.Item
                  initialValue={departmentDefaultValue}
                  name="department"
                  label={t('Department')}
                  rules={[{ required: true, message: t('pls_select_department') }]}
                >
                  <Select loading={departmentsLoading} style={{ width: '100%' }}>
                    {departmentsLoading ? (
                      <Option value="loading" disabled>
                        <Spin size="small" /> {t('loading')}
                      </Option>
                    ) : (
                      departments &&
                      departments?.length > 0 &&
                      departments?.map((item, index) => (
                        <Option key={`dp_${index?.toString()}`} value={item?._id}>
                          {i18n.language === 'ar' ? item?.name_ar : item?.name_us}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  initialValue={regionDefaultValue}
                  name="region"
                  label={t('Region')}
                  rules={[{ required: true, message: 'Please select a region!' }]}
                >
                  <Select loading={regionsLoading} style={{ width: '100%' }}>
                    {regionsLoading ? (
                      <Option value="loading" disabled>
                        <Spin size="small" /> {t('loading')}
                      </Option>
                    ) : (
                      regions &&
                      regions?.length > 0 &&
                      regions?.map((item, index) => (
                        <Option key={`rg_${index?.toString()}`} value={item?._id}>
                          {i18n.language === 'ar' ? item?.name_ar : item?.name_us}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  initialValue={complexityDefaultValue}
                  name="complexity"
                  label={t('Complexity')}
                  rules={[{ required: true, message: t('pls_select_complexity') }]}
                >
                  <Select loading={complexityLoading} style={{ width: '100%' }}>
                    {complexityLoading ? (
                      <Option value="loading" disabled>
                        <Spin size="small" /> {t('loading')}
                      </Option>
                    ) : (
                      complexities &&
                      complexities?.length > 0 &&
                      complexities?.map((item, index) => (
                        <Option key={`cpx_${index?.toString()}`} value={item?._id}>
                          {i18n.language === 'ar' ? item?.name_ar : item?.name_us}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
                <Button htmlType="submit" size="default" type="primary" key="submit">
                  {addCaseLoading ? <LoadingOutlined style={{ fontSize: 20 }} /> : t('add_new_case')}
                </Button>
              </Form>
            </BasicFormWrapper>
          </div>
        </Modal>
      </Main>
    </>
  );
}

export default Cases;
