import React, { lazy, Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import AuthLayout from '../container/profile/authentication/Index';
import { changeRtlMode } from '../redux/themeLayout/actionCreator';

const Landing = lazy(() => import('../components/landing'));
const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const FbLogin = lazy(() => import('../container/profile/authentication/overview/FbSignIn'));
const FbSignUp = lazy(() => import('../container/profile/authentication/overview/FbSignup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));

 function NotFound() {
   return <Redirect to="/" />;
 }

function FrontendRoutes() {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  
  useEffect(() => {
    const html = document.querySelector('html');
    const langDetected = Cookies.get('lang');
    if (!langDetected || langDetected === 'en') {
      html.setAttribute('dir', 'ltr');
      dispatch(changeRtlMode(false));
      i18n.changeLanguage('en');
    } else {
      html.setAttribute('dir', 'rtl');
      dispatch(changeRtlMode(true));
      i18n.changeLanguage('ar');
    }
  }, []);


  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/forgotPassword" component={ForgotPass} />
        {/* <Route exact path="/register" component={SignUp} /> */}
        <Route exact path="/" component={Login} />
        <Route path="/signin" component={Login} />
        {/* <Route exact path="/fbRegister" component={FbSignUp} /> */}
        {/* <Route exact path="/fbSignIn" component={FbLogin} /> */}
        {/* {<Route path="*" component={NotFound} />} */}
      </Suspense>
    </Switch>
  );
}

export default AuthLayout(FrontendRoutes);
