import React from 'react';
import { useTranslation } from 'react-i18next';

const TopMenusMainMenu = () => {
  const { t,i18n } = useTranslation();
  return <p className="sidebar-nav-title" style={{
    fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter'
  }}>{t('Main Menu')}</p>;
};

export default TopMenusMainMenu;
