import Cookies from 'js-cookie';
import axios from 'axios';
import { notification } from 'antd';
import actions from './actions';
import { API_URL } from '../../utility/constants';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  registerBegin,
  registerSuccess,
  registerError,
} = actions;

const login = (body,t, history) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      const res = await axios.post(`${API_URL}/api/user/signin`, body);
      // notification.success({
      //   message: 'Employee signed successfully',
      // });
      Cookies.set('user', JSON.stringify(res?.data?.user));
      Cookies.set('token', res?.data?.token);
      Cookies.set('logedIn', true);
      dispatch(loginSuccess(true));
      history.replace('/admin');
    } catch (err) {
      if (!err?.response) {
        dispatch(loginErr('No Server Response'));
        notification.error({
          message: t('no_server_response'),
        });
      } else if (err.response?.status === 400) {
        dispatch(loginErr(err?.response?.data?.message));
        notification.error({
          message: t(err?.response?.data?.message),
        });
      } else if (err.response?.status === 500) {
        dispatch(loginErr('Internal Server'));
        notification.error({
          message: t('internal_server'),
        });
      }
    }

    // try {
    //   dispatch(loginBegin());
    //   setTimeout(() => {
    //     Cookies.set('logedIn', true);
    //     return dispatch(loginSuccess(true));
    //   }, 1000);
    // } catch (err) {
    //   dispatch(loginErr(err));
    // }
  };
};

const register = (body, history) => {
  return async (dispatch) => {
    try {
      dispatch(registerBegin());
      const res = await axios.post(`${API_URL}/api/user/signup`, body);
      notification.success({
        message: 'Employee registered successfully',
      });
      dispatch(registerSuccess(res?.data));
      history.push('/');
    } catch (err) {
      if (!err?.response) {
        dispatch(registerError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(registerError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 500) {
        dispatch(registerError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

const logOut = (history) => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('user');
      Cookies.remove('token');
      history.replace("/");
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };
