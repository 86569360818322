import React from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '../../../../components/heading/heading';

const HeadingAuthLayout = () => {
  const { t } = useTranslation();
  return (
    <Heading as="h1">
      {t('LawyerSaas')} <br />
      {t('Web Application')}
    </Heading>
  );
};

export default HeadingAuthLayout;
