import actions from './actions';

const {
  GET_DEPARTMENTS_BEGIN,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERR,
  GET_REGIONS_BEGIN,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_ERR,
  GET_COMPLEXITIES_BEGIN,
  GET_COMPLEXITIES_SUCCESS,
  GET_COMPLEXITIES_ERR,
  GET_MATTER_TYPE_BEGIN,
  GET_MATTER_TYPE_SUCCESS,
  GET_MATTER_TYPE_ERR,
  GET_CASES_BEGIN,
  GET_CASES_SUCCESS,
  GET_CASES_ERR,
  ADD_CASE_BEGIN,
  ADD_CASE_SUCCESS,
  ADD_CASE_ERR,
  GET_CLIENTS_BEGIN,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERR,
  DELETE_CASE_BEGIN,
  DELETE_CASE_SUCCESS,
  DELETE_CASE_ERR,
  GET_CASE_DETAILS_BEGIN,
  GET_CASE_DETAILS_SUCCESS,
  GET_CASE_DETAILS_ERR,
  UPDATE_STATUS_BEGIN,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERR,
} = actions;

const initState = {
  departmentsLoading: false,
  casesLoading: false,
  cases: [],
  addCaseLoading: false,
  departments: [],
  regionsLoading: false,
  regions: [],
  complexities: [],
  complexityLoading: false,
  matterTypeLoading: false,
  matterTypes: [],
  clients: [],
  clientLoading: false,
  error: null,
  deleteLoading: false,
  caseDetailsLoading: false,
  caseDetails: null,
  update_status_loading: false,
};

const CaseReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_STATUS_BEGIN:
      return {
        ...state,
        update_status_loading: true,
      };
    case UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        cases: state.cases.map((caseItem) =>
          caseItem._id === data?.caseId ? { ...caseItem, status: data?.status } : caseItem,
        ),
        update_status_loading: false,
      };
    case UPDATE_STATUS_ERR:
      return {
        ...state,
        update_status_loading: false,
      };
    case GET_CASE_DETAILS_BEGIN:
      return {
        ...state,
        caseDetailsLoading: true,
      };
    case GET_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        caseDetails: data,
        caseDetailsLoading: false,
      };
    case GET_CASE_DETAILS_ERR:
      return {
        ...state,
        caseDetailsLoading: false,
      };
    case DELETE_CASE_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };
    case DELETE_CASE_SUCCESS:
      return {
        ...state,
        cases: state.cases.filter((caseItem) => caseItem._id !== data?.caseId),
        deleteLoading: false,
      };
    case DELETE_CASE_ERR:
      return {
        ...state,
        error: err,
        deleteLoading: false,
      };
    case GET_CLIENTS_BEGIN:
      return {
        ...state,
        clientLoading: true,
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: data,
        clientLoading: false,
      };
    case GET_CLIENTS_ERR:
      return {
        ...state,
        error: err,
        clientLoading: false,
      };
    case GET_DEPARTMENTS_BEGIN:
      return {
        ...state,
        departmentsLoading: true,
      };
    case GET_REGIONS_BEGIN:
      return {
        ...state,
        regionsLoading: true,
      };
    case GET_COMPLEXITIES_BEGIN:
      return {
        ...state,
        complexityLoading: true,
      };
    case GET_MATTER_TYPE_BEGIN:
      return {
        ...state,
        matterTypeLoading: true,
      };
    case GET_CASES_BEGIN:
      return {
        ...state,
        casesLoading: true,
      };
    case ADD_CASE_BEGIN:
      return {
        ...state,
        addCaseLoading: true,
      };

    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: data,
        departmentsLoading: false,
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: data,
        regionsLoading: false,
      };
    case GET_COMPLEXITIES_SUCCESS:
      return {
        ...state,
        complexities: data,
        complexityLoading: false,
      };
    case GET_MATTER_TYPE_SUCCESS:
      return {
        ...state,
        matterTypes: data,
        matterTypeLoading: false,
      };
    case GET_CASES_SUCCESS:
      return {
        ...state,
        cases: data,
        casesLoading: false,
      };
    case ADD_CASE_SUCCESS:
      return {
        ...state,
        cases: [data, ...state.cases],
        addCaseLoading: false,
      };
    case GET_DEPARTMENTS_ERR:
      return {
        ...state,
        error: err,
        departmentsLoading: false,
      };
    case GET_REGIONS_ERR:
      return {
        ...state,
        error: err,
        regionsLoading: false,
      };
    case GET_COMPLEXITIES_ERR:
      return {
        ...state,
        error: err,
        complexityLoading: false,
      };
    case GET_MATTER_TYPE_ERR:
      return {
        ...state,
        error: err,
        matterTypeLoading: false,
      };
    case GET_CASES_ERR:
      return {
        ...state,
        error: err,
        casesLoading: false,
      };
    case ADD_CASE_ERR:
      return {
        ...state,
        error: err,
        addCaseLoading: false,
      };

    default:
      return state;
  }
};
export default CaseReducer;
