import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table, Progress, Pagination, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ar-sa';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { ProjectPagination, ProjectListTitle, ProjectList } from './style';
import { deleteCase, getCases } from '../../redux/case/actionCreator';
import Heading from '../../components/heading/heading';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Dropdown } from '../../components/dropdown/dropdown';
import { alertModal } from '../../components/modals/antd-modals';

function CaseLists({ setcasesLen }) {
  const { t, i18n } = useTranslation();
  const { cases, casesLoading } = useSelector((state) => state.case);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCases());
  }, [dispatch]);

  useEffect(() => {
    if (cases?.length > 0) {
      setcasesLen(cases?.length);
    }
  }, [cases]);

  const [state, setState] = useState({
    current: 0,
    pageSize: 0,
  });
  const onShowSizeChange = (current, pageSize) => {
    setState({ ...state, current, pageSize });
  };

  const onHandleChange = (current, pageSize) => {
    // You can create pagination in here
    setState({ ...state, current, pageSize });
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
      return description;
    }
    return `${description.substring(0, maxLength)}...`;
  };
  const dataSource = [];

  const showConfirm = (id) => {
    alertModal.confirm({
      okText: t('OK'),
      cancelText: t('Cancel'),
      title: t('delete_case_h'),
      content: t('delete_case_d'),
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(
            deleteCase(id, (data) => {
              if (data?.success) {
                resolve();
              } else {
                reject();
              }
            }),
          );
        }).catch(() => {
          // Handle the error if necessary
        });
      },
      onCancel() {
        // Handle the cancellation if necessary
      },
    });
  };

  if (cases && cases.length > 0)
    cases.map((value, id) => {
      const { _id, description_us, description_ar, issueDate, status, department, region, complexity, owner } = value;
      return dataSource.push({
        key: id,
        case: (
          <ProjectListTitle>
            <Heading as="h4">
              <Link to={`/admin/cdetails/${_id}`}>
                {description_us && truncateDescription(i18n.language === 'ar' ? description_ar : description_us, 10)}
              </Link>
            </Heading>

            <p
              style={{
                fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter',
              }}
            >
              {' '}
              {description_us && truncateDescription(i18n.language === 'ar' ? description_ar : description_us, 38)}
            </p>
          </ProjectListTitle>
        ),
        department: (
          <span
            className="date-started"
            style={{
              fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter',
            }}
          >
            {i18n.language === 'ar' ? department?.name_ar : department?.name_us}
          </span>
        ),
        issueDate: (
          <span
            className="date-finished"
            style={{
              fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter',
            }}
          >
            {moment(issueDate)
              .locale(i18n.language === 'ar' ? 'ar-sa' : 'en')
              .format('MM/DD/YYYY')}
          </span>
        ),
        region: (
          <p
            style={{
              fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter',
            }}
          >
            {i18n.language === 'ar' ? region?.name_ar : region?.name_us}
          </p>
        ),
        complexity: (
          <p
            style={{
              fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter',
            }}
          >
            {i18n.language === 'ar' ? complexity?.name_ar : complexity?.name_us}
          </p>
        ),
        client: (
          <p
            style={{
              fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter',
            }}
          >
            {i18n.language === 'ar' ? owner?.fullname_ar : owner?.fullname_us}
          </p>
        ),
        status: (
          <Tag className={status === 'Opened' ? 'complete' : status === 'Closed' ? 'progress' : 'late'}>
            {t(status)}
          </Tag>
        ),
        action: (
          <Dropdown
            className="wide-dropdwon"
            content={
              <>
                <Link to={`/admin/cdetails/${_id}`}>{t('View')}</Link>
                <Link to="#" onClick={() => showConfirm(_id)}>
                  {t('Delete')}
                </Link>
              </>
            }
          >
            <Link to="#">
              <FeatherIcon icon="more-horizontal" size={18} />
            </Link>
          </Dropdown>
        ),
      });
    });

  const columns = [
    {
      title: t('Case'),
      dataIndex: 'case',
      key: 'case',
    },
    {
      title: t('Department'),
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: t('Issue Date'),
      dataIndex: 'issueDate',
      key: 'issueDate',
    },
    {
      title: t('Complexity'),
      dataIndex: 'complexity',
      key: 'complexity',
    },
    {
      title: t('Region'),
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: t('Client'),
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <Row gutter={25}>
      <Col xs={24}>
        <Cards headless>
          <ProjectList>
            <div className="table-responsive">
              <Table loading={casesLoading} pagination={false} dataSource={dataSource} columns={columns} />
            </div>
          </ProjectList>
        </Cards>
      </Col>
      <Col xs={24} className="pb-30">
        <ProjectPagination>
          {cases.length ? (
            <Pagination
              locale={{ items_per_page: '' }}
              onChange={onHandleChange}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSize={100}
              defaultCurrent={1}
              total={1}
            />
          ) : null}
        </ProjectPagination>
      </Col>
    </Row>
  );
}

CaseLists.propTypes = {
  setcasesLen: PropTypes.func.isRequired,
};

export default CaseLists;
