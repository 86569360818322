import React from 'react';
import { useTranslation } from 'react-i18next';

const CopyrightText = () => {
  const { i18n } = useTranslation();
  return (
    <span style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter' }} className="admin-footer__copyright">
      ©2024
    </span>
  );
};

export default CopyrightText;
