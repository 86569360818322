import axios from 'axios';
import { notification } from 'antd';
import actions from './actions';
import { API_URL } from '../../utility/constants';

const {
  getDepartmentsBegin,
  getRegionsBegin,
  getComplexitiesBegin,
  getMatterTypeBegin,
  getDepartmentsSuccess,
  getRegionsSuccess,
  getComplexitiesSuccess,
  getMatterTypeSuccess,
  getDepartmentsError,
  getRegionsError,
  getComplexitiesError,
  getMatterTypeError,
  getCasesBegin,
  getCasesSuccess,
  getCasesError,
  addCasesBegin,
  addCasesSuccess,
  addCasesError,
  getClientsBegin,
  getClientsSuccess,
  getClientsError,
  deleteCasesBegin,
  deleteCasesSuccess,
  deleteCasesError,
  getCaseDetailsBegin,
  getCaseDetailsSuccess,
  getCaseDetailsError,
  updateStatusBegin,
  updateStatusSuccess,
  updateStatusError,
} = actions;

const getClients = () => {
  return async (dispatch) => {
    try {
      dispatch(getClientsBegin());
      const res = await axios.get(`${API_URL}/api/client/`);
      dispatch(getClientsSuccess(res?.data));
    } catch (err) {
      if (!err?.response) {
        dispatch(getClientsError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(getClientsError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 500) {
        dispatch(getClientsError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

const getDepartments = () => {
  return async (dispatch) => {
    try {
      dispatch(getDepartmentsBegin());
      const res = await axios.get(`${API_URL}/api/departement/`);
      dispatch(getDepartmentsSuccess(res?.data));
    } catch (err) {
      if (!err?.response) {
        dispatch(getDepartmentsError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(getDepartmentsError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 500) {
        dispatch(getDepartmentsError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

const getRegions = () => {
  return async (dispatch) => {
    try {
      dispatch(getRegionsBegin());
      const res = await axios.get(`${API_URL}/api/region/`);
      dispatch(getRegionsSuccess(res?.data));
    } catch (err) {
      if (!err?.response) {
        dispatch(getRegionsError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(getRegionsError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 500) {
        dispatch(getRegionsError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

const getComplexities = () => {
  return async (dispatch) => {
    try {
      dispatch(getComplexitiesBegin());
      const res = await axios.get(`${API_URL}/api/complexity/`);
      dispatch(getComplexitiesSuccess(res?.data));
    } catch (err) {
      if (!err?.response) {
        dispatch(getComplexitiesError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(getComplexitiesError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 500) {
        dispatch(getComplexitiesError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

const getMatterType = () => {
  return async (dispatch) => {
    try {
      dispatch(getMatterTypeBegin());
      const res = await axios.get(`${API_URL}/api/matterType/`);
      dispatch(getMatterTypeSuccess(res?.data));
    } catch (err) {
      if (!err?.response) {
        dispatch(getMatterTypeError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(getMatterTypeError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 500) {
        dispatch(getMatterTypeError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

const getCases = () => {
  return async (dispatch) => {
    try {
      dispatch(getCasesBegin());
      const res = await axios.get(`${API_URL}/api/case/`);
      dispatch(getCasesSuccess(res?.data));
    } catch (err) {
      if (!err?.response) {
        dispatch(getCasesError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(getCasesError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 500) {
        dispatch(getCasesError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

const createCase = (body,t, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(addCasesBegin());
      const res = await axios.post(`${API_URL}/api/case/create`, body);
      dispatch(addCasesSuccess(res?.data));
      onSuccess();
    } catch (err) {
      if (!err?.response) {
        dispatch(addCasesError('No Server Response'));
        notification.error({
          message: t('no_server_response'),
        });
      } else if (err.response?.status === 400) {
        dispatch(addCasesError(err?.response?.data?.message));
        notification.error({
          message: t(err?.response?.data?.message),
        });
      } else if (err.response?.status === 500) {
        dispatch(addCasesError('Internal Server'));
        notification.error({
          message: t('internal_server'),
        });
      }
    }
  };
};

const deleteCase = (caseId, cb) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCasesBegin());
      await axios.delete(`${API_URL}/api/case/delete/${caseId}`);
      dispatch(deleteCasesSuccess({ caseId }));
      cb({ success: true });
    } catch (err) {
      if (!err?.response) {
        dispatch(deleteCasesError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
        cb({ success: false });
      } else if (err.response?.status === 400) {
        dispatch(deleteCasesError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
        cb({ success: false });
      } else if (err.response?.status === 500) {
        dispatch(deleteCasesError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
        cb({ success: false });
      }
    }
  };
};

const retrieveCaseDetails = (caseId, history) => {
  return async (dispatch) => {
    try {
      dispatch(getCaseDetailsBegin());
      const res = await axios.get(`${API_URL}/api/case/${caseId}`);
      dispatch(getCaseDetailsSuccess(res?.data));
    } catch (err) {
      if (!err?.response) {
        dispatch(getCaseDetailsError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(getCaseDetailsError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 404) {
        history.push('/admin/404');
      } else if (err.response?.status === 500) {
        dispatch(getCaseDetailsError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

const updateStatusCase = (caseId, body, status, t, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(updateStatusBegin());
      await axios.put(`${API_URL}/api/case/updateStatus/${caseId}`, body);
      dispatch(updateStatusSuccess({ caseId, status }));
      notification.success({
        message: t('case_status_updated'),
      });
      onSuccess();
    } catch (err) {
      if (!err?.response) {
        dispatch(updateStatusError('No Server Response'));
        notification.error({
          message: 'No Server Response',
        });
      } else if (err.response?.status === 400) {
        dispatch(updateStatusError(err?.response?.data?.message));
        notification.error({
          message: err?.response?.data?.message,
        });
      } else if (err.response?.status === 500) {
        dispatch(updateStatusError('Internal Server'));
        notification.error({
          message: 'Internal Server',
        });
      }
    }
  };
};

export {
  getDepartments,
  getRegions,
  getComplexities,
  getMatterType,
  getCases,
  createCase,
  getClients,
  deleteCase,
  retrieveCaseDetails,
  updateStatusCase,
};
