import React from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Dropdown } from '../../components/dropdown/dropdown';

function FileDetails() {
  const { t } = useTranslation();
  return (
    <Cards title={t('Files')}>
      <div className="file-list">
        <div className="file-list__single d-flex">
          <div className="file-single-info d-flex">
            <div className="file-single-logo">
              <img src={require(`../../static/img/files/zip.png`)} alt="File Logo" />
            </div>
            <div className="file-single__content">
              <span className="file-name">صلف خاص.zip</span>
              <span className="file-size">7.05 MB</span>
              <span className="file-content-action">
                <Link to="#">{t('Download')}</Link>
              </span>
            </div>
          </div>
          <div className="file-single-action">
            <Dropdown
              className="wide-dropdwon"
              content={
                <>
                  <Link to="#">
                    <FeatherIcon icon="eye" size={14} />
                    {t('View')}
                  </Link>
                  <Link to="#">
                    <FeatherIcon icon="edit" size={14} />
                    {t('Edit')}
                  </Link>
                  <Link to="#">
                    <FeatherIcon icon="trash-2" size={14} />
                    {t('Delete')}
                  </Link>
                </>
              }
            >
              <Link to="#">
                <FeatherIcon icon="more-horizontal" size={16} />
              </Link>
            </Dropdown>
          </div>
        </div>
        {/* End of .file-list__single */}
      </div>
    </Cards>
  );
}

export default FileDetails;
