import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { changeRtlMode } from '../../../redux/themeLayout/actionCreator';

function AuthInfo() {
  const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
  const { i18n, t } = useTranslation();
  const history = useHistory();
  // const logedIn = Cookies.get('user');
  // const token = Cookies.get('token');
  // console.log({
  //   user,
  //   logedIn,
  //   token,
  // });

  // alert(JSON.stringify(user));
  const dispatch = useDispatch();

  const [state, setState] = useState({
    flag: 'english',
  });
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(history));
  };

  const onRtlChange = () => {
    const html = document.querySelector('html');
    html.setAttribute('dir', 'rtl');
    dispatch(changeRtlMode(true));
    i18n.changeLanguage('ar');
    Cookies.set('lang', 'ar');
  };

  const onLtrChange = () => {
    const html = document.querySelector('html');
    html.setAttribute('dir', 'ltr');
    dispatch(changeRtlMode(false));
    i18n.changeLanguage('en');
    Cookies.set('lang', 'en');
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            style={{ width: 25, height: 25, borderRadius: 15 }}
            src={require('../../../static/img/avatar/ukown.jpeg')}
            alt=""
          />
          <figcaption>
            <Heading as="h5">{user ? (i18n.language === 'ar' ? user?.fullname_ar : user?.fullname_us) : ''}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="#">
              <FeatherIcon icon="user" /> {t('Profile')}
            </Link>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> {t('Signout')}
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value) => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link
        onClick={() => {
          onLtrChange();
          onFlagChangeHandle('english');
        }}
        to="#"
      >
        <img
          style={{ width: 20, height: 20, borderRadius: 30 }}
          src={require('../../../static/img/flag/english.png')}
          alt=""
        />
        <span>English</span>
      </Link>
      <Link
        onClick={() => {
          onRtlChange();
          onFlagChangeHandle('arabic');
        }}
        to="#"
      >
        <img
          style={{ width: 20, height: 20, borderRadius: 30 }}
          src={require('../../../static/img/flag/arabic.png')}
          alt=""
        />
        <span>عربي</span>
      </Link>
    </NavAuth>
  );

  useEffect(() => {
    const html = document.querySelector('html');
    const langDetected = Cookies.get('lang');
    if (!langDetected || langDetected === 'en') {
      html.setAttribute('dir', 'ltr');
      dispatch(changeRtlMode(false));
      i18n.changeLanguage('en');
      onFlagChangeHandle('english');
      Cookies.set('lang', 'en');
    } else {
      html.setAttribute('dir', 'rtl');
      dispatch(changeRtlMode(true));
      i18n.changeLanguage('ar');
      onFlagChangeHandle('arabic');
      Cookies.set('lang', 'ar');
    }
  }, []);

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img
              style={{ width: 20, height: 20, borderRadius: 30 }}
              src={require(`../../../static/img/flag/${flag}.png`)}
              alt=""
            />
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={require(`../../../static/img/avatar/ukown.jpeg`)} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
