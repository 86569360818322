import React, { useEffect } from 'react';
import { Input, Row, Col } from 'antd';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Div } from './header-search-style';
// import { headerSearchAction } from '../../redux/headerSearch/actionCreator';
import { Popover } from '../popup/popup';
import { getCases } from '../../redux/case/actionCreator';

const HeaderSearch = ({ darkMode }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { cases, casesLoading } = useSelector((state) => state.case);
  const rtl = useSelector((state) => state.ChangeLayoutMode.rtlData);
  useEffect(() => {
    dispatch(getCases());
  }, [dispatch]);

  const search = (e) => {
    console.log(e.target.value);
  };
  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
      return description;
    }
    return `${description.substring(0, maxLength)}...`;
  };

  const content = (
    <div>
      {cases.length ? (
        cases.map((item) => {
          const { owner, description_ar, description_us, _id } = item;
          return (
            <NavLink key={_id} to={`/admin/cdetails/${_id}`}>
              {i18n.language === 'ar' ? owner?.fullname_ar : owner?.fullname_us}
              <span
                style={{
                  fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter',
                }}
                className="certain-search-item-count"
              >
                {description_us && truncateDescription(i18n.language === 'ar' ? description_ar : description_us, 10)}
              </span>
            </NavLink>
          );
        })
      ) : (
        <NavLink to="#">{t('Data Not Found!')}</NavLink>
      )}
    </div>
  );

  return (
    <>
      <Div
        className="certain-category-search-wrapper"
        style={{ width: '100%', fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter' }}
        darkMode={darkMode}
      >
        <Row className="ant-row-middle">
          <Col md={2} xs={1} className={rtl ? 'text-left' : 'text-right'}>
            <span className="certain-category-icon">
              <FeatherIcon icon="search" size={16} />
            </span>
          </Col>
          <Col md={22} xs={23}>
            <Popover
              placement={!rtl ? 'bottomLeft' : 'bottomRight'}
              content={content}
              title={`${t('Search')} ${t('cases')}`}
              action="focus"
            >
              <Input placeholder={`${t('Search')}...`} onInput={search} />
            </Popover>
          </Col>
        </Row>
      </Div>
    </>
  );
};

HeaderSearch.propTypes = {
  darkMode: PropTypes.bool,
};

export default HeaderSearch;
