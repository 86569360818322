const actions = {
  GET_DEPARTMENTS_BEGIN: 'GET_DEPARTMENTS_BEGIN',
  GET_DEPARTMENTS_SUCCESS: 'GET_DEPARTMENTS_SUCCESS',
  GET_DEPARTMENTS_ERR: 'GET_DEPARTMENTS_ERR',

  GET_REGIONS_BEGIN: 'GET_REGIONS_BEGIN',
  GET_REGIONS_SUCCESS: 'GET_REGIONS_SUCCESS',
  GET_REGIONS_ERR: 'GET_REGIONS_ERR',

  GET_COMPLEXITIES_BEGIN: 'GET_COMPLEXITIES_BEGIN',
  GET_COMPLEXITIES_SUCCESS: 'GET_COMPLEXITIES_SUCCESS',
  GET_COMPLEXITIES_ERR: 'GET_COMPLEXITIES_ERR',

  GET_MATTER_TYPE_BEGIN: 'GET_MATTER_TYPE_BEGIN',
  GET_MATTER_TYPE_SUCCESS: 'GET_MATTER_TYPE_SUCCESS',
  GET_MATTER_TYPE_ERR: 'GET_MATTER_TYPE_ERR',

  GET_CASES_BEGIN: 'GET_CASES_BEGIN',
  GET_CASES_SUCCESS: 'GET_CASES_SUCCESS',
  GET_CASES_ERR: 'GET_CASES_ERR',

  ADD_CASE_BEGIN: 'ADD_CASE_BEGIN',
  ADD_CASE_SUCCESS: 'ADD_CASE_SUCCESS',
  ADD_CASE_ERR: 'ADD_CASE_ERR',

  DELETE_CASE_BEGIN: 'DELETE_CASE_BEGIN',
  DELETE_CASE_SUCCESS: 'DELETE_CASE_SUCCESS',
  DELETE_CASE_ERR: 'DELETE_CASE_ERR',

  GET_CLIENTS_BEGIN: 'GET_CLIENTS_BEGIN',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERR: 'GET_CLIENTS_ERR',

  GET_CASE_DETAILS_BEGIN: 'GET_CASE_DETAILS_BEGIN',
  GET_CASE_DETAILS_SUCCESS: 'GET_CASE_DETAILS_SUCCESS',
  GET_CASE_DETAILS_ERR: 'GET_CASE_DETAILS_ERR',

  UPDATE_STATUS_BEGIN: 'UPDATE_STATUS_BEGIN',
  UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_ERR: 'UPDATE_STATUS_ERR',


  updateStatusBegin: () => {
    return {
      type: actions.UPDATE_STATUS_BEGIN,
    };
  },
  updateStatusSuccess: (data) => {
    return {
      type: actions.UPDATE_STATUS_SUCCESS,
      data,
    };
  },
  updateStatusError: (data) => {
    return {
      type: actions.UPDATE_STATUS_ERR,
      err: data,
    };
  },
  
  getDepartmentsBegin: () => {
    return {
      type: actions.GET_DEPARTMENTS_BEGIN,
    };
  },
  getRegionsBegin: () => {
    return {
      type: actions.GET_REGIONS_BEGIN,
    };
  },
  getComplexitiesBegin: () => {
    return {
      type: actions.GET_COMPLEXITIES_BEGIN,
    };
  },
  getMatterTypeBegin: () => {
    return {
      type: actions.GET_MATTER_TYPE_BEGIN,
    };
  },

  getDepartmentsSuccess: (data) => {
    return {
      type: actions.GET_DEPARTMENTS_SUCCESS,
      data,
    };
  },
  getRegionsSuccess: (data) => {
    return {
      type: actions.GET_REGIONS_SUCCESS,
      data,
    };
  },
  getComplexitiesSuccess: (data) => {
    return {
      type: actions.GET_COMPLEXITIES_SUCCESS,
      data,
    };
  },
  getMatterTypeSuccess: (data) => {
    return {
      type: actions.GET_MATTER_TYPE_SUCCESS,
      data,
    };
  },

  getDepartmentsError: (data) => {
    return {
      type: actions.GET_DEPARTMENTS_ERR,
      err: data,
    };
  },
  getRegionsError: (data) => {
    return {
      type: actions.GET_REGIONS_ERR,
      err: data,
    };
  },
  getComplexitiesError: (data) => {
    return {
      type: actions.GET_COMPLEXITIES_ERR,
      err: data,
    };
  },
  getMatterTypeError: (data) => {
    return {
      type: actions.GET_MATTER_TYPE_ERR,
      err: data,
    };
  },

  getCasesBegin: () => {
    return {
      type: actions.GET_CASES_BEGIN,
    };
  },
  getCasesSuccess: (data) => {
    return {
      type: actions.GET_CASES_SUCCESS,
      data,
    };
  },
  getCasesError: (data) => {
    return {
      type: actions.GET_CASES_ERR,
      err: data,
    };
  },

  getClientsBegin: () => {
    return {
      type: actions.GET_CLIENTS_BEGIN,
    };
  },
  getClientsSuccess: (data) => {
    return {
      type: actions.GET_CLIENTS_SUCCESS,
      data,
    };
  },
  getClientsError: (data) => {
    return {
      type: actions.GET_CLIENTS_ERR,
      err: data,
    };
  },

  addCasesBegin: () => {
    return {
      type: actions.ADD_CASE_BEGIN,
    };
  },
  addCasesSuccess: (data) => {
    return {
      type: actions.ADD_CASE_SUCCESS,
      data,
    };
  },
  addCasesError: (data) => {
    return {
      type: actions.ADD_CASE_ERR,
      err: data,
    };
  },

  deleteCasesBegin: () => {
    return {
      type: actions.DELETE_CASE_BEGIN,
    };
  },
  deleteCasesSuccess: (data) => {
    return {
      type: actions.DELETE_CASE_SUCCESS,
      data,
    };
  },
  deleteCasesError: (data) => {
    return {
      type: actions.DELETE_CASE_ERR,
      err: data,
    };
  },

  getCaseDetailsBegin: () => {
    return {
      type: actions.GET_CASE_DETAILS_BEGIN,
    };
  },
  getCaseDetailsSuccess: (data) => {
    return {
      type: actions.GET_CASE_DETAILS_SUCCESS,
      data,
    };
  },
  getCaseDetailsError: (data) => {
    return {
      type: actions.GET_CASE_DETAILS_ERR,
      err: data,
    };
  },
};

export default actions;
