import React from 'react';
import { Button, Modal, Row } from 'antd';
import Alert from '../../components/alerts/alerts';

const CaseDeleteCallbackModal = ({ visible, onClose }) => {
  return (
    <Modal type={'primary'} title={null} visible={visible} footer={null} onCancel={onClose}>
      <div className="project-modal" style={{ marginBottom: 5 }}>
        <Alert message="Attention" description="Are you sure you want to delete this case" type="warning" />
      </div>
      <Row>
        <Button size="default" type="primary">
          Delete
        </Button>
        <Button onClick={onClose} size="default" outlined type="white">
          Cancelx
        </Button>
      </Row>
    </Modal>
  );
};

export default CaseDeleteCallbackModal;
