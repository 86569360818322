import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spin, Form, Select, Modal, Button } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { ProjectDetailsWrapper } from './style';
import FileDetails from './FileDetails';
import { PageHeader } from '../../components/page-headers/page-headers';
import { BasicFormWrapper, Main } from '../styled';
import Heading from '../../components/heading/heading';
import { Cards } from '../../components/cards/frame/cards-frame';
import { retrieveCaseDetails, updateStatusCase } from '../../redux/case/actionCreator';

const status = [
  {
    id: 0,
    name_us: 'Opened',
    name_ar: 'مفتوح',
  },
  {
    id: 1,
    name_us: 'Closed',
    name_ar: 'مغلق',
  },
];
const { Option } = Select;
function CaseDetails({ match }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { caseDetailsLoading, caseDetails, update_status_loading } = useSelector((state) => state.case);
  const [form] = Form.useForm();
  const [state, setState] = useState({
    visible: false,
    modalType: 'primary',
  });
  useEffect(() => {
    if (match.params.id) {
      dispatch(retrieveCaseDetails(match.params.id, history));
    }
  }, [match.params.id, dispatch]);

  const showModal = () => {
    setState({
      ...state,
      visible: true,
    });
  };

  const onCancel = () => {
    setState({
      ...state,
      visible: false,
    });
  };

  const handleOk = (values) => {
    const onSuccess = () => {
      form.resetFields();
      onCancel();
    };
    if (match.params.id) {
      const body = { ...values };
      dispatch(updateStatusCase(match.params.id, body, values?.status, t, onSuccess));
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <ProjectDetailsWrapper>
      <PageHeader
        ghost
        title={
          <div key="1" className="project-header">
            <Heading as="h2">{t('Case Details')}</Heading>
          </div>
        }
        buttons={[
          <div key="1" className="project-action">
            <Link onClick={showModal} key={1} to="#" className="project-edit">
              <FeatherIcon icon="edit-3" size={14} />
              {t('Edit Status')}
            </Link>
          </div>,
        ]}
      />
      <Main>
        {caseDetailsLoading ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
          <Row gutter={25}>
            <Col xxl={24} xl={24} xs={24}>
              <div
                className="about-project-wrapper"
                style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter' }}
              >
                <Cards title={t('About Case')}>
                  <div className="about-content">
                    <p>{i18n.language === 'ar' ? caseDetails?.description_ar : caseDetails?.description_us}</p>
                  </div>
                  <div className="about-project">
                    <div>
                      <span>{t('Department')}</span>
                      <p>
                        {i18n.language === 'ar' ? caseDetails?.department?.name_ar : caseDetails?.department?.name_us}
                      </p>
                    </div>
                    <div>
                      <span>{t('Region')}</span>
                      <p>{i18n.language === 'ar' ? caseDetails?.region?.name_ar : caseDetails?.region?.name_us}</p>
                    </div>
                    <div>
                      <span>{t('Issue Date')}</span>
                      <p className="color-primary">
                        {moment(caseDetails?.issueDate)
                          .locale(i18n.language === 'ar' ? 'ar-sa' : 'en')
                          .format('MM/DD/YYYY')}
                      </p>
                    </div>
                    <div>
                      <span>{t('Complexity')}</span>
                      <p className="color-danger">
                        {i18n.language === 'ar' ? caseDetails?.complexity?.name_ar : caseDetails?.complexity?.name_us}
                      </p>
                    </div>
                    <div>
                      <span>{t('Owner')}</span>
                      <p>
                        {i18n.language === 'ar' ? caseDetails?.owner?.fullname_ar : caseDetails?.owner?.fullname_us}
                      </p>
                    </div>
                  </div>
                </Cards>
              </div>
            </Col>
            <Col xxl={6} lg={9} xs={24}>
              <div
                className="project-users-wrapper"
                style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter' }}
              >
                <Cards
                  title={t('Assigned To')}
                  // isbutton={
                  //   <Button className="btn-addUser" outlined type="white" size="small">
                  // <FeatherIcon icon="user-plus" size={14} /> Add Users
                  //   </Button>
                  // }
                >
                  <div className="project-users" style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter' }}>
                    <div className="porject-user-single">
                      <div>
                        <img src={require(`../../static/img/avatar/ukown.jpeg`)} alt="" />
                      </div>
                      <div>
                        <Heading as="h5">
                          {i18n.language === 'ar'
                            ? caseDetails?.manager?.fullname_ar
                            : caseDetails?.manager?.fullname_us}
                        </Heading>
                      </div>
                    </div>
                  </div>
                </Cards>
              </div>
            </Col>
            <Col xxl={18} lg={15} xs={24}>
              <FileDetails />
            </Col>
          </Row>
        )}

        <Modal type={state.modalType} title={null} visible={state.visible} footer={null} onCancel={handleCancel}>
          <div style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Inter' }} className="project-modal">
            <BasicFormWrapper>
              <Form form={form} name="updateStatus" onFinish={handleOk}>
                <Form.Item
                  initialValue={status[0]?.name_us}
                  name="status"
                  label={t('Status')}
                  rules={[{ required: true, message: 'Please select a status!' }]}
                >
                  <Select style={{ width: '100%' }}>
                    {status?.map((item, index) => (
                      <Option key={`cpx_${index?.toString()}`} value={item?.name_us}>
                        {i18n.language === 'ar' ? item?.name_ar : item?.name_us}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button htmlType="submit" size="default" type="primary" key="submit">
                  {update_status_loading ? <LoadingOutlined style={{ fontSize: 20 }} /> : t('Update')}
                </Button>
              </Form>
            </BasicFormWrapper>
          </div>
        </Modal>
      </Main>
    </ProjectDetailsWrapper>
  );
}

CaseDetails.propTypes = {
  match: propTypes.object,
};

export default CaseDetails;
